<template>
    <div>

        <AddNonInventoryItem v-if='show_add_non_inventory_modal' @close='show_add_non_inventory_modal = false;' @success='non_inventory_item_add'/>
        <AddInventoryItem v-if='show_add_inventory_modal' @close='show_add_inventory_modal = false;' @success='inventory_item_add'/>

        <div class="container-fluid">
            
            <div class="row mt-4">
                <div class='col-12'>
                    <h4 class="card-title">Add Shipping Ticket</h4>
                </div>
            </div>

            <div class="card mt-3">

                <div class="card-body">
                    <div class="row">
                        <div class="col-4">
                            <div class="pr-2">
                                <h5>Ticket Details</h5>
                                <hr/>
                                <div class="form-group mb-3">
                                    <label>Date</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="zwicon-calendar-never"></i></span>
                                        </div>
                                        <flat-pickr v-model="ticket_date" class="form-control"></flat-pickr>
                                    </div>
                                </div>
                                <br/>
                                <div class="form-group">
                                    <label>Shipping Type</label>
                                    <select class="form-control" v-model="ticket_type">
                                        <option value="shipping">Shipping</option>
                                        <option value="receiving">Receiving</option>
                                    </select>
                                </div>
                                <br/>
                                <div class="form-group mb-0">
                                    <label>Created By <b>Username</b></label>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="pr-2">
                                <h5>
                                    Receiver 
                                    <span v-if='!edit_to' @click='edit_to = true;' class='float-right zwicon-pencil-circle cursor-pointer'></span>
                                    <span v-if='edit_to' @click='edit_to = false;' class='float-right zwicon-checkmark-circle cursor-pointer'></span>
                                </h5>
                                <hr/>
                                <div class="form-group mb-3">
                                    <label class="w-100">To<span class='ml-5 float-right' v-if="!edit_to">{{to_to}}</span></label>
                                    <input type="email" class="form-control" v-if="edit_to" v-model='to_to' placeholder="To">
                                </div>
                                <div class="form-group mb-3">
                                    <label class="w-100">Address<span class='ml-5 float-right' v-if="!edit_to">{{to_address}}</span></label>
                                    <input type="email" class="form-control" v-if="edit_to" v-model='to_address' placeholder="Address">
                                </div>
                                <div class="form-group mb-3">
                                    <label class="w-100">City / Province<span class='ml-5 float-right' v-if="!edit_to">{{to_region}}</span></label>
                                    <input type="email" class="form-control" v-if="edit_to" v-model='to_region' placeholder="Region">
                                </div>
                                <div class="form-group mb-3">
                                    <label class="w-100">Contact Name<span class='ml-5 float-right' v-if="!edit_to">{{to_contact_name}}</span></label>
                                    <input type="email" class="form-control" v-if="edit_to" v-model='to_contact_name' placeholder="Contact Name">
                                </div>
                                <div class="form-group mb-3">
                                    <label class="w-100">Contact #<span class='ml-5 float-right' v-if="!edit_to">{{to_contact_number}}</span></label>
                                    <input type="email" class="form-control" v-if="edit_to" v-model='to_contact_number' placeholder="Contact #">
                                </div>
                                <div class="form-group mb-3">
                                    <label class="w-100">Oil Company<span class='ml-5 float-right' v-if="!edit_to">{{to_oil_company}}</span></label>
                                    <input type="email" class="form-control" v-if="edit_to" v-model='to_oil_company' placeholder="Oil Company">
                                </div>
                                <div class="form-group mb-3">
                                    <label class="w-100">Purchase Order<span class='ml-5 float-right' v-if="!edit_to">{{to_purchase_order}}</span></label>
                                    <input type="email" class="form-control" v-if="edit_to" v-model='to_purchase_order' placeholder="Purchase Order">
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <h5>
                                Sender 
                                <span v-if='!edit_from' @click='edit_from = true;' class='float-right zwicon-pencil-circle cursor-pointer'></span>
                                <span v-if='edit_from' @click='edit_from = false;' class='float-right zwicon-checkmark-circle cursor-pointer'></span>
                            </h5>
                            <hr/>
                            <div class="form-group mb-3">
                                <label class="w-100">From<span class='ml-5 float-right' v-if="!edit_from">{{from_from}}</span></label>
                                <input type="email" class="form-control" v-if="edit_from" v-model='from_from' placeholder="From">
                            </div>
                            <div class="form-group mb-3">
                                <label class="w-100">Address<span class='ml-5 float-right' v-if="!edit_from">{{from_address}}</span></label>
                                <input type="email" class="form-control" v-if="edit_from" v-model='from_address' placeholder="Address">
                            </div>
                            <div class="form-group mb-3">
                                <label class="w-100">City / Province<span class='ml-5 float-right' v-if="!edit_from">{{from_region}}</span></label>
                                <input type="email" class="form-control" v-if="edit_from" v-model='from_region' placeholder="Region">
                            </div>
                            <div class="form-group mb-3">
                                <label class="w-100">Contact Name<span class='ml-5 float-right' v-if="!edit_from">{{from_contact_name}}</span></label>
                                <input type="email" class="form-control" v-if="edit_from" v-model='from_contact_name' placeholder="Contact Name">
                            </div>
                            <div class="form-group mb-3">
                                <label class="w-100">Contact #<span class='ml-5 float-right' v-if="!edit_from">{{from_contact_number}}</span></label>
                                <input type="email" class="form-control" v-if="edit_from" v-model='from_contact_number' placeholder="Contact #">
                            </div>
                            <div class="form-group mb-3">
                                <label class="w-100">Oil Company<span class='ml-5 float-right' v-if="!edit_from">{{from_oil_company}}</span></label>
                                <input type="email" class="form-control" v-if="edit_from" v-model='from_oil_company' placeholder="Oil Company">
                            </div>
                            <div class="form-group mb-3">
                                <label class="w-100">Work Order<span class='ml-5 float-right' v-if="!edit_from">{{from_work_order}}</span></label>
                                <input type="email" class="form-control" v-if="edit_from" v-model='from_work_order' placeholder="Purchase Order">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-3">

                <div class="card-body">
                    <div class="row">
                        <div class="col-12"> 
                            <h5>
                                Inventory Item List
                                <button class="btn btn-primary btn-sm float-right btn--icon-text ml-3" v-tooltip="'Add a Item'" @click='add_inv_item()'><i class="zwicon-plus"></i> Add Item</button>
                            </h5>
                            <table class="table table-sm mb-0 mt-4" v-if='inventory_items !== undefined && inventory_items.length > 0'>
                                <thead class="thead-dark">
                                    <tr>
                                        <th>Serial No</th>
                                        <th>Description</th>
                                        <th>Dirty</th>
                                        <th>Pin Conn</th>
                                        <th>Pro</th>
                                        <th>Box Conn</th>
                                        <th>Pro</th>
                                        <th>Comments</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody style="line-height:2.5rem;">
                                    <tr v-for='(item,index) in inventory_items' v-bind:key="item.inventory_id">
                                        <td>{{item.serial_no}}</td>
                                        <td>{{description_name(item.description)}}</td>
                                        <td>
                                            <div class="checkbox-char checkbox-char-small">
                                                <input :id="'item_dirty'+item.inventory_id" type="checkbox" class="form-control" v-model='item.dirty'>
                                                <label :for="'item_dirty'+item.inventory_id">D</label>
                                            </div>
                                        </td>
                                        <td>
                                            <select id="temp-item-pin" class="form-control" v-model='item.pinn_conn'>
                                                <option value="false">-</option>
                                                <option v-for="pin_conn in connector_list.list" v-bind:key="pin_conn" :value='pin_conn.configuration_id'>{{pin_conn.name}}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <div class="checkbox-char checkbox-char-small">
                                                <input :id="'item_pin_pro'+item.inventory_id" type="checkbox" class="form-control" v-model='item.pin_conn_pro'>
                                                <label :for="'item_pin_pro'+item.inventory_id">P</label>
                                            </div>
                                        </td>
                                        <td>
                                            <select id="temp-item-pin" class="form-control" v-model='item.box_conn'>
                                                <option value="false">-</option>
                                                <option v-for="box_conn in connector_list.list" v-bind:key="box_conn" :value='box_conn.configuration_id'>{{box_conn.name}}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <div class="checkbox-char checkbox-char-small">
                                                <input :id="'item_box_pro'+item.inventory_id" type="checkbox" class="form-control" v-model='item.box_conn_pro'>
                                                <label :for="'item_box_pro'+item.inventory_id">P</label>
                                            </div>
                                        </td>
                                        <td><input id="temp-item-comments" class="form-control" v-model='item.comments'></td>
                                        <td style='text-align: right; position: relative;'>
                                            <button class="btn btn-danger btn-sm" v-tooltip="'Select'" @click='remove_inv_item(index)'><i class="zwicon-minus"></i> Remove</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-3">

                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h5>
                                Non-Inventory Item List
                                <button class="btn btn-primary btn-sm float-right btn--icon-text ml-3" v-tooltip="'Add a Non-Inventory Item'" @click='add_non_inv_item()'><i class="zwicon-plus"></i> Add Item</button>
                            </h5>
                            <table class="table table-sm mb-0 mt-4" v-if='non_inventory_items !== undefined && non_inventory_items.length > 0'>
                                <thead class="thead-dark">
                                    <tr>
                                        <th>Owner</th>
                                        <th>Serial No</th>
                                        <th>Description</th>
                                        <th>Comments</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody style="line-height:2.5rem;">
                                    <tr v-for='(item,index) in non_inventory_items' v-bind:key="item">
                                        <td>
                                            <select id="temp-item-company" class="form-control" v-model='item.company_id'>
                                                <option value="any" selected>-</option>
                                                <option v-for="company in company_list.list" v-bind:key="company" :value="company.company_id">{{company.name}}</option>
                                            </select>
                                        </td>
                                        <td><input id="temp-item-serial_no" class="form-control" v-model='item.serial_no'></td>
                                        <td><input id="temp-item-description" class="form-control" v-model='item.description'></td>
                                        <td><input id="temp-item-comments" class="form-control" v-model='item.comments'></td>
                                        <td style='text-align: right; position: relative;'>
                                            <button class="btn btn-danger btn-sm" v-tooltip="'Select'" @click='remove_noninv_item(index)'><i class="zwicon-minus"></i> Remove</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-3">

                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h5>Shipping Information</h5>
                            <hr/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="pr-2">
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-6 text-left"><label for="call_in" class="w-100">Call In?</label></div>
                                        <div class="col-6 text-right">
                                            <div class="checkbox-char checkbox-char-small float-right">
                                                <input id="call_in" type="checkbox" class="form-control" v-model='shipping_call_in'>
                                                <label for="call_in">C</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-6 text-left"><label for="time" class="w-100">Time</label></div>
                                        <div class="col-6 text-right">
                                            <input id="time" type="text" class="form-control" v-model='shipping_time'>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-6 text-left"><label for="time" class="w-100">Trucking Co</label></div>
                                        <div class="col-6 text-right">
                                            <input id="time" type="text" class="form-control" v-model='shipping_trucking_co'>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-6 text-left"><label for="time" class="w-100">Unit No</label></div>
                                        <div class="col-6 text-right">
                                            <input id="time" type="text" class="form-control" v-model='shipping_unit_no'>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 text-left">
                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col-6 text-left"><label for="time" class="w-100">Driver Name</label></div>
                                    <div class="col-6 text-right">
                                        <input id="time" type="text" class="form-control" v-model='shipping_driver_name'>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col-6 text-left"><label for="call_in" class="w-100">Driver Sign?</label></div>
                                    <div class="col-6 text-right">
                                        <div class="checkbox-char checkbox-char-small float-right">
                                            <input id="driver_sign" type="checkbox" class="form-control" v-model='shipping_driver_sign'>
                                            <label for="driver_sign">S</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 text-left">
                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col-6 text-left"><label for="time" class="w-100">Shipper Name</label></div>
                                    <div class="col-6 text-right">
                                        <input id="time" type="text" class="form-control" v-model='shipping_shipper_name'>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col-6 text-left"><label for="time" class="w-100">Receiver Name</label></div>
                                    <div class="col-6 text-right">
                                        <input id="time" type="text" class="form-control" v-model='shipping_receiver_name'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-12">
                    <ErrorMessage :message="validation_error" v-if='validation_error.length > 0'/>
                    <button class="btn btn-primary btn-md float-right btn--icon-text ml-3" @click='save_ticket()'><i class="zwicon-plus-circle"></i> Add Shipping Ticket</button>
                    
                </div>
            </div>
            <hr class="mt-5">
        </div>
    </div>
</template>

<style scoped>
    label{
        font-weight: bold;
    }
    label span{
        font-weight: normal;
    }
</style>

<script>
    import flatPickr from 'vue-flatpickr-component';
	import { mapGetters } from "vuex";
	import store from "@/store";
	import router from "@/router";

    import AddNonInventoryItem from '@/views/Ticket/Modals/AddNonInventoryItem';
    import AddInventoryItem from '@/views/Ticket/Modals/AddInventoryItem';
    import { LOAD_COMPANY_LIST } from "@/store/actions.type";
    import { ADD_TICKET_ENTRY } from '../../store/actions.type';
	
    import ErrorMessage from '@/components/General/ErrorMessage';

    export default {
        name: 'TicketAdd',
        components: {
           flatPickr,
           AddNonInventoryItem,
           AddInventoryItem,
           ErrorMessage
        },
        props: {
            
        },
        data(){
            return {
                ticket_date: '',

                edit_to: false,
                to_to: '',
                to_address: '',
                to_region: '',
                to_contact_name: '',
                to_contact_number: '',
                to_oil_company: '',
                to_purchase_order: '',

                edit_from: false,
                from_from: '',
                from_address: '',
                from_region: '',
                from_contact_name: '',
                from_contact_number: '',
                from_oil_company: '',
                from_work_order: '',

                shipping_call_in: false,

                show_add_item_modal: false,
                inventory_items: [],
                show_add_non_inventory_modal: false,
                non_inventory_items: [],

                show_add_inventory_modal: false,

                date_config: {
                    altInputClass: 'form-control'
                },

                validation_error: ''
            }
        },
        beforeMount() {
			this.load_company_list(function(){
                
			}, function(){
                
				router.replace({ name: 'inventory', params: { error: "An error occured while attempting to load company list." }});
			});
		},
        computed: {
            ticket_data(){
				return {
					"details": {
						"date": this.ticket_date,
						"type": this.ticket_type,
					},
					"sender":{
						"from": this.from_from,
						"address": this.from_address,
						"city_prov": this.from_region,
						"contact_name": this.from_contact_name,
						"contact_number": this.from_contact_number,
                        "oil_company": this.from_oil_company,
						"po_number": this.from_work_order
					},
					"receiver":{
                        "to": this.to_to,
						"address": this.to_address,
						"city_prov": this.to_region,
						"contact_name": this.to_contact_name,
						"contact_number": this.to_contact_number,
                        "oil_company": this.to_oil_company,
						"po_number": this.to_purchase_order
					},
					"shipping":{
						"time": this.shipping_time,
						"trucking_co": this.shipping_trucking_co,
						"unit_no": this.shipping_unit_no,
						"driver_print": this.shipping_driver_name,
						"driver_sign": this.shipping_driver_sign,
						"call_in": this.shipping_call_in,
						"shipper": this.shipping_shipper_name,
						"receiver": this.shipping_receiver_name
					},
					"inventory_items": this.inventory_items,
					"noninventory_items": this.non_inventory_items
				}
			},
            ...mapGetters(['company_list', 'user', 'loading','inventory_list','size_list','category_list','connector_list','description_list'])
        },
        methods: {
            description_name(desc_id){
                if(this.description_list.list === undefined) return "";
                let item = this.description_list.list.filter(x => x.configuration_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            category_name(desc_id){
                if(this.category_list.list === undefined) return "";
                let item = this.category_list.list.filter(x => x.configuration_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            size_name(desc_id){
                if(this.size_list.list === undefined) return "";
                let item = this.size_list.list.filter(x => x.configuration_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            connection_name(desc_id){
                if(this.connector_list.list === undefined) return "";
                let item = this.connector_list.list.filter(x => x.configuration_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            add_non_inv_item(){
                this.show_add_non_inventory_modal = true;
            },
            non_inventory_item_add(item){
                this.non_inventory_items.push(item);
                this.show_add_non_inventory_modal = false;
            },
            remove_inv_item(index){
                this.inventory_items.splice(index,1);
            },
            remove_noninv_item(index){
                this.non_inventory_items.splice(index,1);
            },
            add_inv_item(){
                this.show_add_inventory_modal = true;
            },
            inventory_item_add(item){
                this.show_add_inventory_modal = false;
                if(this.inventory_items.filter(x => x.inventory_id == item.inventory_id).length > 0) return
                item.dirty = !!item.dirty
                item.box_conn_protector = false;
                item.pinn_conn_protector = false;
                
                this.inventory_items.push(item);
                
            },
            load_company_list(success_fn, failure_fn = () => {}){
				this.list_loading = true;
				this.filter_error = "";
				
				store.dispatch(LOAD_COMPANY_LIST).then(data => {
					success_fn(data);
				}).catch(message => {
					failure_fn(message);
				}).finally(() => {
					this.list_loading = false;
				});
			},
            validate_form(){
                this.validation_error = '';

                if(this.ticket_date == ''){
                    this.validation_error = 'Please select the shipping date for this ticket.';
                    return false;
                }
                if(!['shipping', 'receiving'].includes(this.ticket_type)){
                    this.validation_error = 'Please select the shipping type for this ticket.';
                    return false;
                }

                let total_items = this.inventory_items.length + this.non_inventory_items.length;
                if(total_items == 0){
                    this.validation_error = 'You must add items to a ticket before saving.';
                    return false;
                }

                return true;
            },
            save_ticket(){
                if(!this.validate_form()) return;

                store.dispatch(ADD_TICKET_ENTRY, this.ticket_data).then(() => {
                    router.replace({ name: 'ticket_list'});
				}).catch(message => {
                    console.log("Something went wrong saving ticket: "+message);
				});
            }
        }
    }
</script>